<template>
    <div class="row mt-2">
		<label class="col-4 col-form-label" for="horse_sexe">{{ $t('horse.sexe') }}</label>	
        <div class="col-8">
			<b-input-group :append="getTrad('horse.sexe')">
				<select
					id="horse_sexe"
					class="custom-select"
					ref="select_sexe"
					:placeholder="$t('horse.sexe_cheval')"
					v-on:change="checkSelect"
					v-model="selected_sexe"
					@change="$emit('change', $event.target.value)"
					:disabled="!isEditable"
				>
					<option
						v-for="horse_sexe in horse_sexes"
                        :key="horse_sexe.sexe_code"
						:value="horse_sexe.sexe_code"
					>{{ horse_sexe.sexe_label }}
					</option>
				</select>
			</b-input-group>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Config from "@/mixins/Config.js";
    import HorseMixin from "@/mixins/Horse.js";

	export default {
		mixins: [Config, HorseMixin],
		props: [
			'horse_sexe_code',
			'editable',
			'ficheReadOnly'
		],
		data (){
			return {
				horse_sexes: [],
				selected_sexe: "",
				local_horse_sexe_code: ""
			}
		},
		computed: {
			isEditable () {
				if(this.ficheReadOnly) {
					return false
				}
				else if(this.horse_sexe_code === 'M' || this.horse_sexe_code === 'IND') {
					return true
				}
				return this.editable
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.horse_sexes = await this.getSexes()

				if(this.isEditable && this.horse_sexe_code === 'M')
				{
					this.horse_sexes = this.horse_sexes.filter(sexe => {
						return sexe.sexe_code === "H" || sexe.sexe_code === "M"
					})
				}

				this.local_horse_sexe_code = this.horse_sexe_code
				this.selected_sexe = this.local_horse_sexe_code
			},
			checkSelect() {
				if(this.selected_sexe != this.local_horse_sexe_code ) {
					this.local_horse_sexe_code = this.selected_sexe
				}
			}
		},

		watch:{
			horse_sexe_code(){
				this.selected_sexe = this.horse_sexe_code
				this.init_component()
			}
		}
	}
</script>
